<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>退票管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <!-- 搜索框 -->
      <el-row :gutter="24">
        <el-col :span="8">
            <el-input placeholder="请输入订单号" clearable v-model="queryInfo.orderNo" class="input-with-select">
                <el-button slot="append" icon="el-icon-search" @click="getOrderList()"></el-button>
            </el-input>
        </el-col>
        <el-col :span="8">
            <el-select v-model="queryInfo.status" clearable placeholder="订单状态" @change="getOrderList()">
                <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
        </el-col>
      </el-row>

      <!-- 订单列表 -->
      <el-table :data="orderList" border stripe>
         <el-table-column type="expand">
            <template slot-scope="props">
                <el-form label-position="left" inline class="demo-table-expand">
                    <el-form-item label="订单号: ">
                        <span>{{ props.row.订单号 || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="名称: ">
                        <span>{{ props.row.名称 || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="出票时间: ">
                        <span>{{ props.row.购买时间 || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="价格: ">
                        <span>{{ props.row.价格 || '0' }}</span>
                    </el-form-item>
                    <el-form-item label="预定日期: ">
                        <span>{{ props.row.预计使用时间 || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="验证时间: ">
                        <span>{{ props.row.验证时间 || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="验票员: ">
                        <span>{{ props.row.验票员?.管理员姓名 || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="会员ID: ">
                        <span>{{ props.row.会员ID || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="手机号: ">
                        <span>{{ props.row.手机号 || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="使用状态: ">
                        <span>{{ props.row.使用状态 || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="支付方式: ">
                        <span>{{ props.row.支付方式 || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="关联旅社: " v-if="props.row.是否团队票 == '1'">
                        <span>{{ props.row.团队?.机构名称 || props.row.订单来源 }}</span>
                    </el-form-item>
                    <el-form-item label="订单来源: " v-if="props.row.是否团队票 != '1'">
                        <span>{{ props.row.订单来源 || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="团队名称: " v-if="props.row.是否团队票 == '1'">
                        <span>{{ props.row.来源订单号 || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="订单凭证: " v-if="props.row.是否团队票 != '1'">
                        <span>{{ props.row.来源订单号 || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="微信支付ID: ">
                        <span>{{ props.row.微信支付ID || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="微信退款ID: ">
                        <span>{{ props.row.微信退款ID || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="售票员: ">
                        <span>{{ props.row.售票员?.管理员姓名 || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="核销渠道: ">
                        <span>{{ props.row.核销渠道 || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="商家退款员: ">
                        <span>{{ props.row.退款员?.管理员姓名 || '无' }}</span>
                    </el-form-item>
                    <el-form-item label="订单明细: ">
                        <el-link type="primary" @click="orderDetail(props.row.ID)" underline>查看<i class="el-icon-view el-icon--right"></i> </el-link>
                    </el-form-item>
                </el-form>
            </template>
        </el-table-column>
        <el-table-column label="订单号" prop="订单号"></el-table-column>
        <el-table-column label="订单名称" prop="名称"></el-table-column>
        <el-table-column label="出票时间" prop="购买时间"></el-table-column>
        <el-table-column label="使用状态" prop="使用状态"></el-table-column>
        <el-table-column label="价格" prop="价格"></el-table-column>
        <el-table-column label="支付方式" prop="支付方式"></el-table-column>
        <el-table-column label="售票员" prop="售票员.管理员姓名"></el-table-column>
        <el-table-column
        fixed="right"
        label="操作"
        width="100">
        <template slot-scope="scope">
            <el-button @click="refundHandleClick(scope.row.ID)" type="text" size="small">退款审核</el-button>
        </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-size="queryInfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 订单明细 -->
    <el-dialog title="订单明细页" :visible.sync="showInfoDialogVisible" width="50%">
      <template>
        <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="订单号: ">
                <span>{{ orderDetailInfo.订单号 || '无' }}</span>
            </el-form-item>
            <el-form-item label="总金额: ">
                <span>{{ orderDetailInfo.价格 || '无' }}</span>
            </el-form-item>
            <el-form-item label="出票时间: ">
                <span>{{ orderDetailInfo.购买时间 || '无' }}</span>
            </el-form-item>
            <el-form-item label="预定日期: ">
                <span>{{ orderDetailInfo.预计使用时间 || '无' }}</span>
            </el-form-item>
        </el-form>
        
        <!-- 订单明细 -->
        <el-table :data="orderDetailInfo.订单明细" border stripe>
            <el-table-column type="index"></el-table-column>
            <el-table-column label="名称" prop="名称"></el-table-column>
            <el-table-column label="单价" prop="价格"></el-table-column>
            <el-table-column label="数量" prop="数量"></el-table-column>
            <el-table-column label="剩余数量" prop="剩余数量"></el-table-column>
        </el-table>
                
        <!-- 订单人员 -->
        <el-table :data="orderDetailInfo.订单人员" v-if="orderDetailInfo.订单人员 && orderDetailInfo.订单人员.length > 0" border stripe>
            <el-table-column type="index"></el-table-column>
            <el-table-column label="客户姓名" prop="姓名"></el-table-column>
            <el-table-column label="手机号" prop="手机号码"></el-table-column>
            <el-table-column label="身份证号" prop="身份证号码"></el-table-column>
        </el-table>
        
        <!-- 订单座位 -->
        <el-table :data="orderDetailInfo.订单座位" v-if="orderDetailInfo.订单座位 && orderDetailInfo.订单座位.length > 0" border stripe>
            <el-table-column type="index"></el-table-column>
            <el-table-column label="座位名称" prop="名称"></el-table-column>
            <el-table-column label="区域" prop="区域名"></el-table-column>
            <el-table-column label="演出厅" prop="影厅名称"></el-table-column>
        </el-table>

        <div class="sub-title" v-if="orderDetailInfo.isRefund">提示：请确认无误后再进行下一步操作</div>
        
        <div slot="footer" class="dialog-footer" v-if="orderDetailInfo.isRefund">
            <el-button @click="showInfoDialogVisible = false">拒绝</el-button>
            <el-button type="primary" @click="sureRefundHandleClick(orderDetailInfo.订单号)">通过审核</el-button>
        </div>
    </template>

    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "order",
  data() {
    return {
      queryInfo: {
        searchStr: "",
        // 当前的页数
        currentPage: 1,
        pageSize: 10,
        status: '5',
        startTime: '',
        endTime: '',
        userId: '',
        orderNo: '',
      },
      total: 0,
      showInfoDialogVisible: false,
      orderList: [],
      statusOptions: [
        // {
        //   value: '0',
        //   label: '未支付'
        // }, 
        // {
        //   value: '1',
        //   label: '已支付'
        // }, 
        // {
        //   value: '2',
        //   label: '已完成'
        // }, 
        // {
        //   value: '3',
        //   label: '已退款'
        // }, 
        // {
        //   value: '4',
        //   label: '已关闭'
        // },
        {
          value: '5',
          label: '退票审核'
        }
      ],
      orderDetailInfo: {},
      orderDetailPage: 1,
      orderTypeOptions: [],
      goodsTotal: 0,
    };
  },
  created() {
    this.getOrderList();
  },
  computed: {},
  methods: {
    // 获取列表数据
    getOrderList() {
        let isAdmin = window.sessionStorage.getItem("isAdmin");
        if (isAdmin === "0") {
            this.$message.error('无操作权限');
            return
        }

        this.$axios({
          method: 'get',
          url: "/manager/orderList",
          params: this.queryInfo,
        })
        .then((res) => {
          // console.log(res.data);
          this.orderList = res.data;
          this.total = res.total;
        })
        .catch((err) => err);
    },
    //页码改变函数
    handleCurrentChange(newPage) {
      this.queryInfo.currentPage = newPage;
      this.getOrderList();
    },
    // 列表详情
    orderDetail(id, isRefund) {
        this.orderDetailInfo = {}
        console.log(id)
        this.$axios({
            method: 'get',
            url: "/manager/orderDetail",
            params: {
                orderId: id,
                pageNum: this.orderDetailPage
            },
        })
        .then((res) => {
          // console.log(res.data);
          if (res.code == 200) {
            this.orderDetailInfo = res.data;
            this.orderDetailInfo.isRefund = isRefund;
            this.showInfoDialogVisible = true
          } else {
              this.$message.warning(res.msg)
          }
        })
        .catch((err) => err);
    },
    refundHandleClick(id) {
        console.log("退款 ========>> ", id)
        this.orderDetail(id, 1)
    },
    sureRefundHandleClick(id) {
      console.log("确认退款 ========>> ", id)

      let that = this
      const loading = that.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      let data = {
        apiOrder: id,
        auditRes:true,
        cancelMemo:'agree'
      };
      // 审核退款
      that.$axios({
          url: `/pft/cancelOrder`,
          method: "POST",
          data,
      })
        .then((res) => {
          console.log(res);
          loading.close();
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "退票成功!",
            });

            this.showInfoDialogVisible = false
            this.getOrderList()
          } else {
            that.$message.warning(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
          
          that.$message.warning("网络异常，请稍后再试")
          loading.close();
        });
    },
  },
};
</script>

<style scoped>

  .sub-title{
    font-size: 12px;
    color: red;
  }
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }

</style>